<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader
            type="paragraph"
            class="mt-3"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="white grid justify-items-center px-0 py-10 text-left" v-else>
      <v-col cols="12" sm="11" class="pa-0 mt-10 pt-10" v-if="items.length > 0">
        <v-row justify="space-between" class="px-3 mb-5">
          <v-col cols="4" class="hidden sm:block">
          </v-col>
          <v-col cols="8">
            <div class="font-bold text-2xl sm:text-5xl pa-0 mt-5" v-if="bahasa">
              Pengajuan Proposal
            </div>
            <div class="font-bold text-2xl sm:text-5xl pa-0 mt-5" v-else>
              Call for Proposal
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="px-3">
          <v-col cols="4" class="sticky top-32 h-full">
            <div class="font-bold text-xl sm:text-2xl mb-2">
              Windows
            </div>
            <v-skeleton-loader
              v-if="isLoad"
              type="paragraph"
              class="mt-5"
            ></v-skeleton-loader>
            <v-treeview
              v-else
              :active.sync="active"
              :open.sync="open"
              :items="items"
              activatable
              return-object
              item-key="id"
              open-on-click
              :hoverable="false"
              class="guideline-tree"
              active-class="guideline-tree-active"
            >
            </v-treeview>
          </v-col>
          <v-col cols="8" class="mb-10">
            <v-scroll-y-transition mode="out-in">
              <v-col cols="12" class="pa-0">
                <div v-if="active.length === 0">
                  <div class="text-black mb-3 pb-0 text-xl sm:text-2xl font-bold">
                    Overview
                  </div>
                </div>
                <v-card v-else class="pt-0" flat>
                  <v-card-text class="pt-0 px-0">
                    <div class="text-black mb-0 pb-0 text-xl sm:text-2xl font-bold">
                      {{ active[0].name }}
                    </div>
                    <v-skeleton-loader
                      v-if="isLoadDetail"
                      type="paragraph"
                      class="mt-5"
                    ></v-skeleton-loader>
                    <v-row class="my-5 px-2" v-for="(proposal, index) in active[0].data" :key="'proposal' + index">
                      <v-col cols="auto" class="pt-0 hidden sm:block">
                        <img src="@/assets/images/icon_program.svg" width="80%"/>
                      </v-col>
                      <v-col cols="11" sm="11">
                        <v-row>
                          <span class="font-bold text-2xl text-black">
                            {{ proposal.program.name }}
                          </span>
                          <v-spacer />
                          <v-btn class="btn-primary font-bold text-capitalize hidden sm:block">
                            <a :href="`mailto:${proposal.email}`" class="white--text text-decoration-none">
                              <span v-if="bahasa">Ajukan Proposal</span>
                              <span v-else>Submit Proposal</span>
                            </a>
                          </v-btn>
                          <v-col cols="12" class="text-black pa-0">
                            <span class="text-sm py-3">{{ proposal.createdDate }}</span>
                          </v-col>
                          <v-col
                            cols="12"
                            class="pa-0 text-black mt-2"
                            v-if="!newArray[index].isExpand && strLimit(proposal.desc).length < 100"
                            v-html="strLimit(proposal.desc, 100)"
                          >
                          </v-col>
                          <v-col
                            v-else
                            cols="12"
                            class="pa-0 text-black mt-2"
                            v-html="proposal.desc"
                          >
                          </v-col>
                          <div class="text-primary cursor-pointer font-bold mt-3 mb-2" @click="changed(index, true)" v-if="!newArray[index].isExpand">
                            <u>{{bahasa? 'Perluas Detail' : 'Expand Details'}}</u>
                            <v-icon small color="green darken-1">expand_more</v-icon>
                          </div>
                          <div class="text-primary cursor-pointer font-bold mt-3 mb-2" @click="changed(index, false)" v-if="newArray[index].isExpand">
                            <u>{{bahasa? 'Sembunyikan Detail' : 'Collapse Details'}}</u>
                            <v-icon small color="green darken-1">expand_less</v-icon>
                          </div>
                          <v-col cols="12" class="pa-0 sm:hidden">
                            <v-btn class="btn-primary font-bold text-capitalize mt-2">
                              <a :href="`mailto:${proposal.email}`" class="white--text text-decoration-none">
                                <span v-if="bahasa">Ajukan Proposal</span>
                                <span v-else>Submit Proposal</span>
                              </a>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="text-left mt-3 py-10">
                      <v-spacer/>
                      <v-col cols="auto">
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                          color="green darken-1"
                          next-icon="navigate_next"
                          prev-icon="chevron_left"
                        ></v-pagination>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="11" class="pa-0 mt-10 py-10" v-else>
        <v-row>
          <v-col cols="12" sm="6">
            <img src="@/assets/images/405.png" width="100%"/>
          </v-col>
          <v-col cols="12" sm="6" class="text-black flex flex-wrap content-center">
            <v-col cols="12" class="text-3xl font-bold pl-0">{{bahasa? 'Tidak Ada Data Pengajuan Proposal yang Tersedia' : 'No Call for Proposal Data Available'}}</v-col>
            <v-btn class="btn-primary mt-8" :to="{name: 'home'}">
              {{bahasa ? 'kembali ke beranda' : 'back to home'}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
	props: {
		bahasa: {
			type: Boolean,
			default: true
		}
	},
  data() {
    return {
			slickOptionsStory: {
        slidesToShow: 3,
        prevArrow: '.nextStory-btn',
        nextArrow: '.backStory-btn',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
			is_expand: false,
			page: 1,
			pageCount: 0,
			isLoad: false,
			isLoadDetail: false,
			baseUrl: process.env.VUE_APP_BASE_URL,
			baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      scrollSettings: {
        maxScrollbarLength: 60
      },
      progressDialogLoading: false,
      active: [],
      open: [],
      tree: [],
      items: [],
      newArray: []
    };
  },
  watch: {
    open(val) {
      if (val.length > 1) {
        this.open.splice(0, 1);
      }
    },
		language() {
			this.getData();
		},
    newArray(val) {
      console.log('watch', val)
    },
    active(val) {
			this.getDetail(val[0].id);
		},
    page() {
			this.getDetail(this.active[0].id);
    }
  },
	computed: {
		language() {
      return this.bahasa ? 'ID' : 'ENG';
    },
	},
  mounted() {
		this.getData();
  },
  methods: {
    changed(item, val) {
      this.newArray[item].isExpand = val;
    },
		goToProgram(link, param) {
			this.$router.push({
        name: link,
				params: {
					id: param
				}
      });
		},
		goTo(link, param, type) {
			this.$router.push({
        name: link,
				params: {
          id: param,
          type: '/' + type
        }
      });
		},
		async getData() {
			this.isLoad = true;
      let params = {
        lang: this.language
      }
      await axios.get(this.baseUrl + '/proposal', {params: params}).then(response => {
        this.items = response.data.data;
        // this.items = [
        //   {
        //     id: 1,
        //     name: 'tes'
        //   },
        //   {
        //     id: 2,
        //     name: 'tes2'
        //   }
        // ]
				this.active = [this.items[0]];
				this.getDetail(this.active[0].id);
      })
      .catch(error => {
        Swal.fire('Failed!', error.response.data.message, 'error');
      })
      .finally(() => this.isLoad = false);
		},
		async getDetail(id) {
			this.isLoadDetail = true;
      let params = {
        lang: this.language,
        page: this.page
      }
      await axios.get(this.baseUrl + '/proposal/list/' + id, {params: params}).then(response => {
        this.active[0].data = response.data.data.data.map(item => {
          return {
            ...item,
            is_expand: false
          };
        });
        for(var a = 0; a < this.active[0].data.length; a++) {
          this.newArray.push({isExpand: false});
        }
				this.page = response.data.data.current_page;
				this.pageCount = response.data.data.last_page;
      })
      .catch(error => {
        Swal.fire('Failed!', error.response.data.message, 'error');
      })
      .finally(() => this.isLoadDetail = false);
		}
  }
};
</script>
<style>
.drawer-menu--scroll-guideline {
  max-height: 380px;
  overflow: auto;
}
.drawer-menu--scroll-guideline-menu {
  max-height: 450px;
  overflow: auto;
}
@media (min-height: 768px) {
  .drawer-menu--scroll-guideline {
    max-height: 500px !important;
    overflow: auto;
  }
  .drawer-menu--scroll-guideline-menu {
    max-height: 570px !important;
    overflow: auto;
  }
}
</style>
